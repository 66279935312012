import http from "@/utils/http";

export const getNewsList = (data) => {
  return http.request({
    url: "/front/news/title.dbys",
    method: "get",
    params: data,
  });
};
export const getNewsDetails = (data) => {
  return http.request({
    url: "/front/news/article.dbys",
    method: "get",
    params: data,
  });
};
