<template>
  <div id="nav">
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  </div>
  <router-view/>
</template>

<style lang="scss">
  html{color:#555555;background-color:#fff;_text-overflow:ellipsis;}
  *{margin:0;padding:0}
  body,button,input,select,textarea{font:14px PingFangSC-Regular,Tahoma,Microsoft Yahei;_font-size:62.5%}
  h1,h2,h3,h4,h5,h6,span{font-size:1rem}
  a{text-decoration:none;cursor:pointer;color:#222}
  a:visited,a[tabindex='-1']:focus,a:active{outline:0 none}
  @media screen and (-ms-high-contrast:active),(-ms-high-contrast:none){a:active{background-color:transparent}}
  small,cite,code,em,th,i,b{font-size:1rem;font-style:normal;font-weight:500}
  input,select,textarea,button{font-size:1rem;-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box;outline:0 none}
  button,input[type=button],input[type=submit]{cursor:pointer}
  button::-moz-focus-inner,button::-moz-focus-outer,input::-moz-focus-inner,input::-moz-focus-outer{border:0 none;padding:0;margin:0}
  input[type=search]{-webkit-appearance:textfield}
  input:focus::-webkit-input-placeholder{color:transparent}
  input::-ms-clear{display:none}
  textarea{resize:vertical;overflow-y:auto}
  table{border-collapse:collapse;border-spacing:0}
  th{text-align:inherit}
  fieldset,img,iframe{border:0 none}
  img{display:inline-block;vertical-align:bottom;-ms-interpolation-mode:bicubic}
  img::shadow div{border:none!important}
  iframe{display:block}
  ul,ol{list-style:none}
  li{vertical-align:top}
  q:before,q:after{content:''}

  .fl{float: left;}
  .fr{float: right;}
  .cf:after{clear:both; content:' '; display:block; width: 0; height: 0; visibility:hidden}
  .cf{*zoom:1}

  a:hover {text-decoration: underline;}
</style>
