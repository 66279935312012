import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "ABOUT US",
    component: () => import("../views/about.vue"),
  },
  {
    path: "/partners",
    name: "PARTNERS",
    component: () => import("../views/partners.vue"),
  },
  {
    path: "/career",
    name: "CAREER",
    component: () => import("../views/career.vue"),
  },
  {
    path: "/news",
    name: "NEWS",
    component: () => import("../views/news.vue"),
  },
  {
    path: "/product",
    name: "PRODUCT",
    component: () => import("../views/product.vue"),
  },
  {
    path: "/newdetails",
    name: "DETAILS",
    component: () => import("../views/newdetails.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  // window.pageYOffset = 0;
  window.scrollTo(0, 0);
  next()

})
export default router
