
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'Footer',
  setup() {
    const linkTag = ref(0)
    const scrollToTop = () => {
      document.documentElement.scrollTop = 0;
      // window.pageYOffset = 0; // 兼容ios
      document.body.scrollTop = 0; // 兼容低版本ie
    }
    return {
      linkTag,
      scrollToTop
    }
  }
});
