
import { defineComponent, ref } from 'vue'
import TopBar from '@/components/TopBar.vue'
import Footer from '@/components/Footer.vue'
import { getNewsList, getNewsDetails } from '@/api/information'

export default defineComponent({
  name: 'Home',
  components: {
    TopBar,
    Footer,
  },

  setup () {
    const more = ref(' >')
    const newsList = ref([])
    const baseUrl = ref('https://www.otimes.com/otimes_zw/img/')
    const defaultTag = ref(1)
    const changeTag = ref(0)
    const position = ref(0)
    const typeId = ref(16)
    const contentId = ref(10067)
    const content = ref({
      content_id: contentId.value
    })
    const getInformationList = async () => {
      const res: any = await getNewsList({
        type_id: typeId.value,
        page_id: 1,
        page_limit: 5
      })
      if (res.meta.success) {
        newsList.value = res.data.list
        if (newsList.value[0].typeImg.indexOf('http') == -1) {
          defaultTag.value = 0
        }
      }
    }
    const prep = () => {
      if (changeTag.value != 0) {
        changeTag.value -= 1
        position.value -= 615
      }
    }
    const next = () => {
      if (changeTag.value != 3) {
        changeTag.value += 1
        position.value += 615
      }
      console.log(changeTag.value)
    }
    const styleNum = () => {
      return `right: ${position.value}px;`
    }
    getInformationList()
    // getInformationDetails()
    return {
      more,
      newsList,
      baseUrl,
      defaultTag,
      typeId,
      contentId,
      content,
      changeTag,
      position,
      getInformationList,
      prep,
      next,
      styleNum,
    }
  }
});
